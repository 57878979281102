import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import jwt_decode from 'jwt-decode';
import { getAccessTokenByRefreshToken } from '@/api';
import { requestPageLog } from '@/api/common';
import { isMobile } from '@/lib/Detect';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.onError(error => {
  if (/loading chunk .* failed./i.test(error.message)) {
    window.location.reload();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to.meta.title) {
    // 닥플 | 대한민국 의사 커뮤니티 Docple
    Vue.nextTick(() => {
      document.title = to.meta.title;
    });
  }
});

// 페이지 진입시 refreshToken 가진 사용자 일 경우 30초 마다 refresh Token 유효한지 체크. 없으면 로그아웃 시켜버림.
const checkRefreshToken = () => {
  // refreshToken 만료 시간 지나면 alert 후 login 페이지로 이동. 로그인 만료된 페이지 redirect=로 물고 이동
  if (!VueCookies.get('refreshToken')) {
    clearInterval(interval.data);
    VueCookies.remove('accessToken');
    VueCookies.remove('dpLoginKeep');
    store.commit('resetState', null);
    alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
    location.href = '/login?redirect=' + location.href;
  }
};

// interval 추가용 Object
const interval = {
  data: null,
};

router.beforeEach(async (to, from, next) => {
  // window.location.href = '/maintenance';
  if (to.meta.error) next();

  if (VueCookies.get('refreshToken')) {
    const uid = jwt_decode(VueCookies.get('refreshToken'))?.uid;
    // accessToken 이 있을 때
    if (VueCookies.get('accessToken')) {
      if (store.getters.userId !== uid) {
        await store.dispatch('getUserInfo');
      }
      store.commit('isLoggedIn', true);
    } else {
      // accessToken 이 없을 때, getToken 용 uid 설정
      await store.commit('setUid', uid);
      await getAccessTokenByRefreshToken();
      if (store.getters.loggedIn) await store.dispatch('getUserInfo');
    }
    // 페이지 진입시 refreshToken 주기적 체크 현재 30초
    clearInterval(interval.data);
    interval.data = setInterval(checkRefreshToken, 30 * 1000);
  } else {
    // 로그아웃 후 interval 남아있는 케이스 해지
    clearInterval(interval.data);
    store.commit('resetState', null);
  }

  if (VueCookies.get('communityToken')) {
    store.commit('authCommunity', true);
  }

  if (to.matched.some(record => isMobile && !record.meta.useMobile)) {
    next({
      path: '/mobile/notFound', // 모바일 사용안하는 메뉴일 때 준비중 페이지
    });
  }

  if (to.matched.some(record => record.meta.requireAuth && !store.getters.loggedIn)) {
    next({
      path: '/login',
      query: { redirect: to.fullPath === '/' ? undefined : to.fullPath, jstl: 'N' },
    });
  }

  if (to.path === '/' || to.path === '/index.do') {
    if (isMobile && !store.getters.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath === '/' ? undefined : to.fullPath, jstl: 'N' },
      });
    }

    // mobile home url의 경우인데 pc로 온 경우
    if (!isMobile && to.query.type) {
      const type = to.query.type;
      if (type === 'class') {
        if (store.getters.loggedIn) {
          location.href = '/class';
        } else {
          next({
            name: 'Login',
            query: { redirect: '/class', replace: 'N' },
          });
        }
      }
    }

    // 비회원 로그
    if (to.meta.menuid1) {
      requestPageLog({
        url: to.fullPath,
        menuid1: to.meta.menuid1,
        menuid2: to.meta.menuid2,
        menuid3: to.query?.subCode ? to.query.subCode : null,
        menuid4: to.params.id ? to.params.id : to.query.bid ? to.query.bid : null,
      });
    }
    next();
  }

  // 커뮤니티 인증이 필요한 페이지 인데, 커뮤니티 인증이 안되어있을 때
  if (to.meta.requireCommunityAuth && !store.getters.authCommunity && !store.getters.isMangerOrHigher) {
    next({
      path: '/community/password',
      query: { redirect: to.fullPath, jstl: 'N' },
    });
  }

  if (to.meta.menuid1) {
    const { uid, joinType } = store.getters.userInfo;
    requestPageLog({
      uid,
      joinType,
      url: to.fullPath,
      menuid1: to.meta.menuid1,
      menuid2: to.meta.menuid2,
      menuid3: to.query?.subCode ? to.query.subCode : null,
      menuid4: to.params.id ? to.params.id : to.query.bid ? to.query.bid : null,
    });
  }
  next(); // 반드시 next()를 호출하십시오!
});

// cf ) NavigationDuplicated 에러 https://velog.io/@hschoi1104/Vue.js-NavigationDuplicated-%EC%97%90%EB%9F%AC-%ED%95%B4%EA%B2%B0%ED%95%98%EA%B8%B0
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err && err.name !== 'NavigationDuplicated') throw err;
  });
};
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

export default router;
